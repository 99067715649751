<template>
  <div>
    <b-container fluid style="background-image: url('/img/commodities2.jpg'); background-repeat: no-repeat; background-size: cover; height: 300px; margin: 0px;">
      <b-row>
        <b-col style="color: white;">
          <b-container>
            <b-row>
              <b-col>
                <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 150px;">KPMG Commodities Insights</h1>
                <p style="font-size: 18px;">Explore KPMG point of view on commodities related regulatory matters</p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mt-4">
      <b-row class="mb-4">
        <b-col class="mb-4">
          <div v-if="!loadingNews && article">
            <div>
              <b-container class="shadow p-5" style="background-color: #1E49E2; color: white; font-size: 1.3em;">
                <b-row>
                  <b-col lg="8" sm="12">
                    <div class="mb-4"><b-link :to="{ name: 'ControlsInsight', params: {id: article.id }}" style="color: white; font-size: 24px;"><strong>{{article.title}}</strong></b-link></div>
                    <div class="mb-2"><small>{{moment(article.approved_at).format('YYYY-MM-DD')}} by {{ article.approved_by }}</small></div>
                    <div>{{ article.summary }}</div>
                  </b-col>
                  <b-col class="text-center" lg="4" sm="12">
                    <div>
                      <div :style="article.style"></div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
              <div v-if="articles.length > 0">
                <div v-for="item in articles" :key="'articles'+item.id" class="my-5" style="font-size: 1.2em;">
                  <b-container class="shadow p-5 bg-white">
                    <b-row>
                      <b-col class="text-center" lg="4" sm="12">
                        <div>
                          <div :style="item.style"></div>
                        </div>
                      </b-col>
                      <b-col lg="8" sm="12">
                        <div><b-link :to="{ name: 'ControlsInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                        <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                        <div>{{ item.summary }}</div>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3" v-if="loadingNews">
            <b-spinner label="checking news..." />
            loading news...
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

export default {
  name: 'CommoditiesInsights',
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'commodities insights', action: 'open commodities insights' })
    document.title = "KPMG Commodities Insights"
    await this.loadNews()
  },
  data () {
    const data = {
      article: null,
      articles: [],
      loadingNews: true,
      moment: moment
    }
    return data
  },
  methods: {
    loadNews: async function () {
      this.loadingNews = true
      try {
        const articles = await this.$Amplify.API.get('cosmos', `/articles/topicgroup/1/limit/10`)
        articles.forEach(x => {
          const img = getUsernameFromEmail(x.approved_by)
          x.img = img
          x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
        })
        const article = articles.shift()
        article.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${article.img}.jpg'); background-position: center; background-size: cover; width: 250px; height: 250px;`
        this.article = article
        this.articles = _.uniqBy(articles, 'id')
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style>
</style>
